<div class="relative block">
    <div (click)="onTriggerClick()" (mouseenter)="onTriggerMouseEnter()">
        <ng-content select="[vsfDropdownTrigger]"></ng-content>
    </div>
    <ng-template #contentTemplate>
        <div class='flex flex-col mt-2 rounded-md border shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div *ngIf='closeButton' class="width-full bg-white px-2 flex justify-end border-b">
                <button class='btn-icon' (click)="close()" title='Close menu'>
                    <fa-icon [icon]="times"></fa-icon>
                </button>
            </div>
            <div
                #contentElement
                (click)="stopEventPropagation($event)"
                class="overflow-y-auto"
                [ngClass]="widthClass ?? 'w-56'"
                role="menu"
                aria-orientation="vertical"
                tabindex="-1"
                cdkTrapFocus
                [cdkTrapFocusAutoCapture]="true"
            >
                <ng-content select="[vsfDropdownContent]"></ng-content>
            </div>
        </div>
    </ng-template>
</div>

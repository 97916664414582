import { Injectable } from '@angular/core';
import { CartFragment } from '../../../common/gql/graphql';
import { OrderWithCustomerFragment } from '../../../common/generated-types';
import { GtagPurchaseData, UAPurchaseData } from './gtag-types';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let dataLayer: {
    push: (event: { [key: string]: string | Record<string, any> | null }) => void;
};

@Injectable({
    providedIn: 'root',
})
export class GtagService {
    private readonly googleAnalyticsId = 'G-8W3ZRHWRSK';

    addToCart(order: CartFragment, productVariantId: string) {
        if (typeof dataLayer !== 'undefined') {
            const line = order.lines.find((l) => l.productVariant.id === productVariantId);
            if (!line) {
                return;
            }
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    currency: order.currencyCode,
                    value: this.formatMoney(line.linePriceWithTax),
                    items: [
                        {
                            item_id: line.productVariant.id,
                            item_name: line.productVariant.name,
                            price: this.formatMoney(line.unitPriceWithTax),
                            quantity: line.quantity,
                        },
                    ],
                },
            });
        }
    }

    purchase(order: OrderWithCustomerFragment) {
        const data: GtagPurchaseData & UAPurchaseData = {
            transaction_id: order.code,
            value: this.formatMoney(order.totalWithTax),
            currency: order.currencyCode,
            tax: this.formatMoney(order.totalWithTax - order.total),
            shipping: this.formatMoney(order.shippingWithTax),
            items: order.lines.map((line) => ({
                item_id: line.productVariant.id,
                item_name: line.productVariant.name,
                price: this.formatMoney(line.unitPriceWithTax),
                quantity: line.quantity,
            })),
            purchase: {
                actionField: {
                    id: order.code,
                    revenue: this.formatMoney(order.totalWithTax),
                    tax: this.formatMoney(order.totalWithTax - order.total),
                    shipping: this.formatMoney(order.shippingWithTax),
                },
                products: order.lines.map((line) => ({
                    id: line.productVariant.id,
                    name: line.productVariant.name,
                    price: this.formatMoney(line.unitPriceWithTax),
                    quantity: line.quantity,
                })),
            },
        };
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: 'purchase',
                ecommerce: data,
            });
        }
    }

    /**
     * According to this thread, Google Analytics expects the value to be a number
     * https://support.google.com/analytics/thread/203875397?hl=en&msgid=207586046
     *
     * Also
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#make_a_purchase_or_issue_a_refund
     */
    private formatMoney(minorUnits: number): number {
        return minorUnits / 100;
    }
}

import { ConnectedPosition, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    Input,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';

import { A11yModule } from '@angular/cdk/a11y';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgClass } from '@angular/common';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DropdownTriggerDirective } from './dropdown-trigger.directive';

export type DropdownPosition =
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
/**
 * A generic dropdown component.
 *
 * @example
 * ```
 * <vsf-dropdown #dropdown [position]="['top']">
 *
 *     <button vsfDropdownTrigger class="btn btn-secondary">Open it!</button>
 *
 *     <div class="card" vsfDropdownContent>
 *         <p>Here's the dropdown content!</p>
 *         <button class="btn" (click)="dropdown.close()">Close</button>
 *     </div>
 *
 * </vsf-dropdown>
 * ```
 */
@Component({
    selector: 'vsf-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    exportAs: 'vsfDropdown',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        FontAwesomeModule,
        A11yModule,
        NgClass,
    ],
})
export class DropdownComponent {
    /** If true, the dropdown will close when the user clicks anywhere on the document */
    @Input() closeOnDocumentClick = true;
    /** If true, the dropdown will close when the user clicks any item in the dropdown */
    @Input() closeOnItemClick = false;
    /** If true, the dropdown will close when the user clicks the trigger if the dropdown is currently open */
    @Input() closeOnTriggerClick = false;
    /** If true, the dropdown will open when the trigger element is hovered with the mouse */
    @Input() openOnHover = false;
    /** Sets the preferred position of the dropdown. Actual position depends on available space */
    @Input() position: DropdownPosition[] = ['bottom'];
    /** Tailwind class to set the width of the dropdown. Defaults to w-56 */
    @Input() widthClass?: string;
    @Input() closeButton = false;

    @ContentChild(DropdownTriggerDirective, { read: ElementRef }) trigger: ElementRef;
    @ViewChild('contentTemplate', { read: TemplateRef }) contentTemplate: TemplateRef<any>;
    @ViewChild('contentElement', { read: ElementRef }) contentElement: ElementRef<any>;
    private closeFn: (() => any) | null = null;
    private clickSubscriber: Subscription;
    private mouseoverSubscriber: Subscription;
    private isOpen = false;
    times = faTimes;

    constructor(private overlay: Overlay, private viewContainerRef: ViewContainerRef) {}

    onTriggerClick() {
        if (this.isOpen && this.closeOnTriggerClick) {
            this.close();
        } else {
            this.open();
        }
    }

    onTriggerMouseEnter() {
        if (this.openOnHover && this.closeFn == null) {
            this.open();
        }
    }

    /**
     * Stop the click event bubbling up from the dropdown content so as not to cause it to close.
     */
    stopEventPropagation(e: MouseEvent) {
        if (this.closeOnItemClick) {
            this.closeFn?.();
        }
        e.stopPropagation();
    }

    open() {
        this.close();
        const positionStrategy = this.getPositionStrategy();
        const scrollStrategy = this.overlay.scrollStrategies.reposition();
        const overlayRef = this.overlay.create(
            new OverlayConfig({
                scrollStrategy,
                positionStrategy,
                maxHeight: 500,
            }),
        );
        this.closeFn = () => {
            overlayRef.dispose();
            this.closeFn = null;
        };
        overlayRef.attach(new TemplatePortal(this.contentTemplate, this.viewContainerRef));

        if (this.closeOnDocumentClick) {
            this.registerClickSubscriber();
        }
        if (this.openOnHover) {
            this.registerMouseoverSubscriber();
        }
        this.isOpen = true;
    }

    close() {
        if (typeof this.closeFn === 'function') {
            this.closeFn();
        }
        if (this.clickSubscriber) {
            this.clickSubscriber.unsubscribe();
        }
        if (this.mouseoverSubscriber) {
            this.mouseoverSubscriber.unsubscribe();
        }
        this.isOpen = false;
    }

    private registerClickSubscriber() {
        this.clickSubscriber = fromEvent<MouseEvent>(document, 'click')
            .pipe(
                filter((event) => {
                    const clickTarget = event.target as HTMLElement;
                    return (
                        clickTarget !== this.trigger.nativeElement &&
                        !this.trigger.nativeElement.contains(event.target)
                    );
                }),
                take(1),
            )
            .subscribe(() => {
                this.close();
            });
    }

    private registerMouseoverSubscriber() {
        this.mouseoverSubscriber = fromEvent<MouseEvent>(document, 'mouseover')
            .pipe(
                debounceTime(200),
                filter((e) => {
                    const contentEl = this.contentElement.nativeElement;
                    const triggerEl = this.trigger.nativeElement;
                    // In a server context, the .contains method would not exist.
                    if (contentEl && typeof contentEl.contains === 'function') {
                        return !(contentEl.contains(e.target) || triggerEl.contains(e.target));
                    }
                    return true;
                }),
                take(1),
            )
            .subscribe((e) => {
                this.close();
            });
    }

    private getPositionStrategy(): PositionStrategy {
        const position: { [K in DropdownPosition]: ConnectedPosition } = {
            top: {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom',
            },
            right: {
                originX: 'end',
                originY: 'center',
                overlayX: 'start',
                overlayY: 'center',
            },
            bottom: {
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
                panelClass: 'foo-panel',
            },
            left: {
                originX: 'start',
                originY: 'center',
                overlayX: 'end',
                overlayY: 'center',
            },
            ['top-left']: {
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
            },
            ['top-right']: {
                originX: 'end',
                originY: 'top',
                overlayX: 'end',
                overlayY: 'bottom',
            },
            ['bottom-left']: {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
            },
            ['bottom-right']: {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
            },
        };

        return this.overlay
            .position()
            .flexibleConnectedTo(this.trigger)
            .withDefaultOffsetX(0)
            .withPositions([...this.position.map((p) => position[p]) /*...Object.values(position)*/])
            .withPush(true);
    }
}
